import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

const Nav = (props) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          menuLinks {
            name
            url
          }
        }
      }
    }
  `)

  return (
    <nav id="nav" className={props.sticky ? 'alt' : ''}>
      <ul>
        { data.site.siteMetadata.menuLinks.map((nav) => 
          <li key={nav.name} className={props.active===nav.url ? 'is-active' : ''}>
            <a className="navlink" href={`/` + nav.url}>{nav.name}</a>
          </li>
        ) }
      </ul>
    </nav>
  )
}

export default Nav
