import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import logo from '../assets/images/crabber-logo.svg';

const HeaderGeneric = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <header id="header" className="simple">
      <div className="logo"><img src={logo} alt="" /></div>
      <div className="page-title-wrap">
        <div className="h1 site-title"><a href="/">{data.site.siteMetadata.title}</a></div>
      </div>
    </header>
  )
}

export default HeaderGeneric
