import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import Nav from '../components/Nav'

const ImpressumPage =({ data }) => (
	<Layout>
		<Helmet title={[`Crabber-Webservice`, data.nodePage.title].join(' - ')} />
		<HeaderGeneric title={data.nodePage.title} />
		<Nav active="blog" />
		<div id="main">
			<article className="main">
				<header className="major">
					<h1>{data.nodePage.title}</h1>
				</header>
				<div
					className="content"
        			dangerouslySetInnerHTML={{ __html: data.nodePage.body.processed }}
      			></div>
			</article>
		</div>
	</Layout>
)

export default ImpressumPage

export const query = graphql`
	query($slug: String!) {
		nodePage(fields: { slug: { eq: $slug } }) {
			title
			body {
				processed
			}
		}
	}
`